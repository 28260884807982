<template>
    <div class="page-wrap">
        <div class="session-form-hold">
            <base-card>
                <v-card-text class="text-center">
                    <div class="pt-1">
                        <h5 class="font-weight-bold">
                            {{ $t('users.signUp.title') }}
                        </h5>
                        <p class="text--disabled font-weight-medium">
                            {{ $t('users.signUp.subTitle') }}
                        </p>
                    </div>
                    <v-avatar
                        tile
                        size="160"
                        class="mb-0"
                    >
                        <img
                            src="@/assets/img/illustrations/posting_photo.svg"
                            alt=""
                        >
                    </v-avatar>

                    <v-form v-model="isValid">
                        <v-text-field
                            v-model="userInfo.firstname.value"
                            class="purple-input"
                            :label="$t('users.login.firstname')"
                            :error-messages="userInfo.firstname.error"
                            :rules="[rules.required]"
                            @keyup="userInfo.firstname.error = ''"
                        />

                        <v-text-field
                            v-model="userInfo.lastname.value"
                            class="purple-input"
                            :label="$t('users.login.lastname')"
                            :error-messages="userInfo.lastname.error"
                            @keyup="userInfo.lastname.error = ''"
                        />

                        <v-text-field
                            v-model="userInfo.userid.value"
                            class="purple-input"
                            :label="$t('users.login.username')"
                            :error-messages="userInfo.userid.error"
                            :rules="[rules.required]"
                            @keyup="userInfo.userid.error = ''"
                        />

                        <v-text-field
                            v-model="userInfo.email.value"
                            :label="$t('users.login.email')"
                            class="purple-input"
                            :error-messages="userInfo.email.error"
                            :rules="[rules.required, rules.validEmail]"
                            @keyup="userInfo.email.error = ''"
                        />

                        <!--    :hint="Texto cuando el field no es invalido"-->
                        <v-text-field
                            v-model="userInfo.password.value"
                            class="purple-input"
                            :label="$t('users.login.password')"
                            :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.validPassword]"
                            :type="showIcon ? 'text' : 'password'"
                            name="input-10-2"
                            :error-messages="userInfo.password.error"
                            @click:append="showIcon = !showIcon"
                            @keyup="userInfo.password.error = ''"
                        />

                        <v-text-field
                            v-model="userInfo.password_confirmation.value"
                            class="purple-input"
                            :label="$t('users.login.passwordConfirm')"
                            :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.minLength8, rules.passwordMatch]"
                            :type="showIcon ? 'text' : 'password'"
                            name="input-10-2"
                            :error-messages="userInfo.password_confirmation.error"
                            @click:append="showIcon = !showIcon"
                            @keyup="userInfo.password_confirmation.error = ''"
                        />

                        <!--                        <v-text-field
                            v-model="email"
                            label="email"
                            :rules="emailRules"
                            required
                        />

                        <v-text-field
                            v-model="ePassword"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            name="input-10-2"
                            label="password"
                            :counter="10"

                            @click:append="show = !show"
                        />
                        <v-text-field
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'Confirm Password'"
                            name="input-10-2"
                            label="Password"
                            value="Pa"

                            @click:append="show = !show"
                        />-->
                    </v-form>
                    <!--                    <v-checkbox
                        v-model="checkbox1"
                        :label="$t('users.login.rememberPass')"
                    />-->
                    <v-btn
                        :disabled="!isValid && !serverCheck"
                        class="mb-4"
                        block
                        color="primary"
                        @click="submit"
                    >
                        {{ $t('users.signUp.signUp') }}
                    </v-btn>
                    <div class="">
                        {{ $t('users.signUp.alreadyHaveAccount') }}
                        <v-btn
                            text
                            small
                            color="primary"
                            to="/app/sessions/sign-in-two"
                        >
                            {{ $t('users.login.signIn') }}
                        </v-btn>
                    </div>
                </v-card-text>
            </base-card>
        </div>
    </div>
</template>
<script>
import axios from '@/plugins/axios'
import { mapActions } from 'vuex'
import rules from '@/helpers/rulesValidate'

export default {
    name: 'Signup',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Registrarse'
    },
    data() {
        return {
            isValid: true,
            serverCheck: false,
            showIcon: false,

            checkbox1: true,
            checkbox2: false,

            loading: false,
            rules: {
                ...rules,
                passwordMatch: () => this.userInfo.password.value === this.userInfo.password_confirmation.value ||
                    this.$t('rules.passwordConfirmation')
            },
            userInfo: this.loadUserInfo()
        }
    },
    async created() {
        if (!(await this.canSignUp())) {
            this.$nextTick(() => {
                this.$router.push('/app/sessions/sign-in-two')
            })
        }
    },
    methods: {
        ...mapActions(['setUser']),
        async canSignUp() {
            const configs = await axios
                .get('configs/1');

            let options = configs?.data?.data?.attributes?.options;
            if (options) {
                options = JSON.parse(options);
                return options.canRegister;
            }

            return false;
        },
        async submit() {
            this.loading = true;

            try {
                const result = await axios
                    .post("/register", {
                        name: this.userInfo.firstname.value,
                        lastname: this.userInfo.lastname.value,
                        username: this.userInfo.userid.value,
                        email: this.userInfo.email.value,
                        password: this.userInfo.password.value,
                        password_confirmation: this.userInfo.password_confirmation.value
                    });

                this.loading = false;

                if (result.status === 201) {
                    const token = result.data.token;
                    const user = result.data.user;
                    const roles = [];

                    this.setUser({
                        token,
                        user,
                        roles
                    });

                    this.$router.push('/')
                }
            } catch (error) {
                /* console.log('error *******')
                console.log(error.response.data.errors) */

                this.loading = false;
                if (error.response.status === 422) {
                    var errors = error.response.data.errors;
                    // {"firstname":["The firstname must be a string."],"lastname":["The lastname must be a string."],"userid":["The userid must be a string."],"email":["The email must be a string.","The email must be a valid email address."],"pwd":["The pwd confirmation does not match."]}
                    if (errors.name) { this.userInfo.firstname.error = this.getServerValidation(errors.name); } // "The name field is required."
                    if (errors.lastname) { this.userInfo.lastname.error = this.getServerValidation(errors.lastname); }
                    if (errors.username) { this.userInfo.userid.error = this.getServerValidation(errors.username, 'username'); }
                    if (errors.email) { this.userInfo.email.error = this.getServerValidation(errors.email, 'email'); } // "The email has already been taken."
                    if (errors.password) { this.userInfo.password.error = this.getServerValidation(errors.password, 'password'); } //  "The password must be at least 6 characters."
                    if (errors.password) { // password_confirmation
                        this.userInfo.password_confirmation.error = this.getServerValidation(errors.password, 'password_confirmation'); // "The password confirmation does not match."
                    }
                }
            }
            /* setTimeout(() => {
                console.log('done')
            }, 1000) */
        },
        getServerValidation(values, field = "") {
            for (let i = 0; i < values.length; i++) {
                const value = values[i];
                if (value) {
                    if (value.indexOf("required") !== -1) {
                        return this.$t('rules.required');
                    } else if (field === 'username' && value.indexOf("already been taken") !== -1) {
                        return this.$t('rules.usernameTaken');
                    } else if (field === 'email' && value.indexOf("already been taken") !== -1) {
                        return this.$t('rules.emailTaken');
                    } else if (field === 'password' && value.indexOf("password must be at least") !== -1) {
                        return this.$t('rules.password');
                    } else if (field === 'password_confirmation' && value.indexOf("password confirmation does not match") !== -1) {
                        return this.$t('rules.passwordConfirmation');
                    }
                }
            }

            return "";
        },
        loadUserInfo() {
            return {
                firstname: {
                    value: "",
                    error: ""
                },
                lastname: {
                    value: "",
                    error: ""
                },
                userid: {
                    value: "",
                    error: ""
                },
                email: {
                    value: "",
                    error: ""
                },
                password: {
                    value: "",
                    error: ""
                },
                password_confirmation: {
                    value: "",
                    error: ""
                }
            }
        }
        // signUp: function() {
        //   // console.log(this.email, this.ePassword);
        //   firebase.auth().createUserWithEmailAndPassword(this.email, this.ePassword)
        //     .then(
        //       user => {
        //         console.log(user.data);

        //       },
        //       err => {
        //         console.log(err);

        //       }
        //     )
        // }
    }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    //background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
